<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_billingContractList" />
      <!-- 請求契約一覧 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 取引先-->
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="suppliersSelected"
                  :items="supplierList"
                  :label="$t('label.lbl_supplier')"
                  :hint="setSrhSupplier()"
                  class="txt-single"
                  persistent-hint
                  :rules="[rules.supplierRequired]"
                ></v-autocomplete>
              </div>
              <span class="require asterisk-spacer" style="float: left">*</span>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="search">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
            </div>
          </v-row>

          <!-- ３列目 -->
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on" small>
                      {{ $t("10") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ $t("50") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ $t("100") }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            :page.sync="page"
            :server-items-length="itemsPerPage"
            disable-filtering
            :hide-default-footer="true"
            :options.sync="sortOptions"
            height="511px"
          >
            <!-- 業務No -->
            <template v-slot:[`item.bussinessNo`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.bussinessNo"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_billingBussinessNo')"
                  :rules="[rules.bussinessNoRequired]"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.bussinessNo }}</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <!-- 業務名 -->
            <template v-slot:[`item.bussinessName`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.bussinessName"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_bussinessName')"
                  :rules="[rules.bussinessNameRequired]"
                >
                </v-text-field>
              </div>
              <div v-else>{{ item.bussinessName }}</div>
            </template>
            <!-- 荷主締め日 -->
            <template v-slot:[`item.shipperCutOffDate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.shipperCutOffDate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_shipperCutOffDate')"
                  :rules="[rules.shipperCutOffDateRequired]"
                ></v-text-field>
              </div>
              <div v-else>{{ item.shipperCutOffDate }}</div>
            </template>
            <!-- 支払日 -->
            <template v-slot:[`item.paymentDate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.paymentDate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_paymentDate')"
                  :rules="[rules.paymentDateRequired]"
                ></v-text-field>
              </div>
              <div v-else>{{ item.paymentDate }}</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </div>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_BIL_CONTRACT_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    singleSelect: false,
    pListPopup: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 取引先
    suppliersSelected: "",
    // メニュー
    openMenu: null,
    // 入荷予定日付カレンダー
    dateMenu: false,
    dateMenu2: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    sortOptions: {},
    sortItem: "",
    // 総件数
    totalCount: 0,
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 業務No
      {
        text: i18n.tc("label.lbl_bussinessNo"),
        value: "bussinessNo",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 業務名
      {
        text: i18n.tc("label.lbl_bussinessName"),
        value: "bussinessName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 荷主締め日
      {
        text: i18n.tc("label.lbl_shipperCutOffDate"),
        value: "shipperCutOffDate",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 支払日
      {
        text: i18n.tc("label.lbl_paymentDate"),
        value: "paymentDate",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "3%",
        align: "center",
        sortable: false,
      },
    ],
    detailDialogData: {},
    inputList: [],
    dummy_supplierList: [
      { text: "", value: "" },
      { text: "TOYO", value: "4508300", name: "4508300" },
    ],
    supplierList: [],
    defaultItem: {
      bussinessNo: "",
      bussinessName: "",
      shipperCutOffDate: "",
      paymentDate: "",
    },
    apiReqParam: {
      clientSid: "",
      utcDate: "",
    },
    rules: {
      yyyymmdd: (value) =>
        dateTimeHelper.validDate(commonUtil.zen2han(value), "/") || "日付が間違っています",
      supplierRequired: (value) =>
        !!value || i18n.tc("label.lbl_supplier") + i18n.tc("check.chk_input_variable"),
      bussinessNoRequired: (value) =>
        !!value || i18n.tc("label.lbl_billingBussinessNo") + i18n.tc("check.chk_input_variable"),
      bussinessNameRequired: (value) =>
        !!value || i18n.tc("label.lbl_bussinessName") + i18n.tc("check.chk_input_variable"),
      shipperCutOffDateRequired: (value) =>
        !!value || i18n.tc("label.lbl_shipperCutOffDate") + i18n.tc("check.chk_input_variable"),
      paymentDateRequired: (value) =>
        !!value || i18n.tc("label.lbl_paymentDate") + i18n.tc("check.chk_input_variable"),
    },
  }),
  methods: {
    // 検索ボタン
    search(isInit) {
      this.u_10_check();

      if (this.$refs.form.validate() || isInit) {
        this.getBusinessSpecificationsMaster();
      }
    },

    // 取引先API接続
    getSupplierList(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    //  テーブルフッターの高さ変更
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.getBusinessSpecificationsMaster();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getBusinessSpecificationsMaster();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.suppliersSelected = "";
      this.getSupplierList();
      this.billingType = "";
      this.txt_billingCode = "";
      this.situation = "";
      this.inputList = [];
      let param = this.$route.params.selectData;
      if (param != null && !this.clearFlg) {
        this.suppliersSelected = param;
        this.search(true);
      }
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    jumpTo(item) {
      if (this.$refs.form.validate()) {
        item.suppliersSelected = this.inputList[0].clientSid;
        this.$router.push({
          name: appConfig.SCREEN_ID.P_BIL_004,
          params: {
            selectData: item,
          },
        });
      }
    },

    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // 通常/スポットリストボックス取得処理
    setBillingType() {
      for (var i = 0; i < this.billingTypes.length; i++) {
        if (this.billingTypes[i].value == this.billingType) {
          return this.billingTypes[i].text;
        }
      }
    },

    // 状況リストボックス取得処理
    setSituation() {
      for (var i = 0; i < this.situations.length; i++) {
        if (this.situations[i].value == this.situation) {
          return this.situations[i].text;
        }
      }
    },

    // 行追加
    addItem() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_BIL_004,
      });
    },
    /**
     * G業務仕様マスタ取得
     */
    getBusinessSpecificationsMaster() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      config.params.clientSid = this.suppliersSelected;
      config.params.reqComReferenceDate = dateTimeHelper.convertJST();
      // ページャー処理
      if (this.page >= 2 && this.inputList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }
      // console.debug("getBusinessSpecificationsMaster() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_BIL_CONTRACT, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getBusinessSpecificationsMaster() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // ページング
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              jsonData.resIdv.billContract.forEach((row) => {
                list.push({
                  bussinessNo: row.businessNo,
                  bussinessName: row.gggnak,
                  shipperCutOffDate: row.ggsime,
                  paymentDate: row.ggsiha,
                  clientSid: row.clientSid,
                });
              });
              this.inputList = list;
              resolve(list);
              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("getBusinessSpecificationsMaster() Resolve", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    constractStartDateToCal(val) {
      this.constractStartDateTo = this.formatDate(val);
    },
    constractStartDateFromCal(val) {
      this.constractStartDateFrom = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getBusinessSpecificationsMaster();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_BIL_001_SORT[sortBy] + ascdesc;
          this.search();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}
.toatlNum {
  margin-left: 10px;
}

// テーブルフッターの高さ調整
// .minheight {
//   margin-bottom: 0px;
// }
</style>
